import { Flex, Link, Text } from '@chakra-ui/react'
import { LogoHorizontal, UserCircle } from '../Icon'
import NextLink from 'next/link'

interface Props {}

export default function Header({}: Props) {
  const buttonStyle = {
    padding: '12px 24px',
    borderWidth: 1,
    borderRadius: '6px',
    fontWeight: 600,
    borderColor: '#4DF9AE',
    fontSize: '14px'
  }

  return (
    <Flex
      align={'center'}
      w={'100%'}
      maxW={1440}
      justify={'space-between'}
      py={'16px'}
      px={'20px'}
      as={'nav'}
      gap={'32px'}
      flexWrap={'wrap'}
    >
      <Flex>
        <Text as={'h1'} srOnly>
          GPT Max
        </Text>
        <Link as={NextLink} href="/">
          <LogoHorizontal w={['100px', '126px']} h={'100%'} />
        </Link>
      </Flex>

      <Flex align={'center'} gap={'16px'} flexWrap={'wrap'}>
        <Link
          as={NextLink}
          href="/register?plan=free"
          style={buttonStyle}
          bgColor={'green.300'}
          color={'grey.900'}
          _hover={{
            textDecoration: 'none',
            bgColor: 'transparent',
            color: 'white'
          }}
          display={['none', null, 'flex']}
          id="free-access"
        >
          Acesse gratuitamente
        </Link>
        <Link
          href="/#plans"
          style={buttonStyle}
          _hover={{
            textDecoration: 'none',
            bgColor: 'green.300',
            color: 'grey.900'
          }}
          display={['none', null, 'flex']}
        >
          Planos
        </Link>
        <Link
          as={NextLink}
          href="/login"
          style={buttonStyle}
          _hover={{
            textDecoration: 'none',
            bgColor: 'green.300',
            color: 'grey.900',
            svg: {
              stroke: 'grey.900'
            }
          }}
        >
          <Flex align={'center'} gap={'10px'}>
            <UserCircle w={'26px'} h={'26px'} />
            <Text as={'span'}>Acessar conta</Text>
          </Flex>
        </Link>
      </Flex>
    </Flex>
  )
}
