import { Button, Divider, Flex, Image, Link, Text } from '@chakra-ui/react'
import {
  CodeQuick,
  CopyGenerator,
  DankiCodeAI,
  EbookGenerator,
  Gpt32k,
  LogoHorizontal,
  VoiceClone
} from '@/component/Icon'
import { Instagram, Youtube, Linkedin, Twitter } from 'lucide-react'
import { BsWhatsapp } from 'react-icons/bs'

interface Props {}

export default function Footer({}: Props) {
  return (
    <Flex
      justify={'center'}
      align={'center'}
      as={'section'}
      w={'100%'}
      pb={'60px'}
      flexDirection={'column'}
      gap={'40px'}
      bgColor={'grey.950'}
    >
      <Flex
        w={'100%'}
        className="title-animation"
        gap={'56px'}
        align={'center'}
        bgColor={'grey.900'}
        pt={'30px'}
      >
        {[...new Array(12)].map((item, index) => (
          <Flex className={'block-animation'} align={'center'} key={index}>
            <Text fontSize={'56px'} fontWeight={'bold'}>
              Treine sua IA
            </Text>
            <Flex w={'12px'} h={'12px'} bgColor={'green.200'} ml={'56px'} />
          </Flex>
        ))}
      </Flex>
      <Flex
        pt={'60px'}
        w={'100%'}
        gap={'32px'}
        maxW={1216}
        justify={'space-between'}
        px={'20px'}
        flexWrap={'wrap'}
      >
        <Flex flexDirection={'column'} maxW={520} gap={'24px'}>
          <Link href="https://dankicode.ai" isExternal>
            <Flex>
              <DankiCodeAI w={'210px'} h={'100%'} />
            </Flex>
          </Link>
          <Text color={'grey.100'} fontSize={'sm'}>
            A Danki Code AI é uma empresa líder no desenvolvimento e
            fornecimento de soluções avançadas de Inteligência Artificial para
            atender tanto a empresas quanto a consumidores. Nossas soluções de
            IA são projetadas para abranger uma ampla variedade de setores e
            necessidades, desde otimização de processos empresariais até
            aprimoramento da experiência do cliente. Danki Code AI
          </Text>
        </Flex>

        <Flex flexDirection={'column'} gap={'16px'}>
          <Text fontWeight={'bold'}>Institucional</Text>

          <Link href={'#plans'} mt={'8px'} color={'grey.200'} fontSize={'sm'}>
            Planos
          </Link>
          <Link
            href={'/register'}
            mt={'8px'}
            color={'grey.200'}
            fontSize={'sm'}
          >
            Cadastre-se
          </Link>
          <Link href={'/login'} mt={'8px'} color={'grey.200'} fontSize={'sm'}>
            Acessar conta
          </Link>
          <Link href={'/terms'} mt={'8px'} color={'grey.200'} fontSize={'sm'}>
            Termos e condições de uso
          </Link>
          <Link href={'/cookies'} mt={'8px'} color={'grey.200'} fontSize={'sm'}>
            Políticas de Cookies
          </Link>
        </Flex>

        <Flex flexDirection={'column'} gap={'16px'}>
          <Text fontWeight={'bold'}>Fale com o nosso suporte</Text>
          <Flex gap={'24px'}>
            <Button
              as={Link}
              variant={'link'}
              fontWeight={'normal'}
              href={'https://dankicode.ai/contato/'}
              mt={'8px'}
              color={'grey.200'}
              fontSize={'sm'}
              target="_blank"
            >
              Suporte
            </Button>
          </Flex>
          <Text fontWeight={'bold'}>Acompanhe-nos</Text>
          <Flex gap={'24px'}>
            <Link
              _hover={{
                svg: { stroke: 'green.400' }
              }}
              href="https://www.instagram.com/dankicode.ai/"
            >
              <Instagram size={32} />
            </Link>
            <Link
              _hover={{
                svg: { stroke: 'green.400' }
              }}
              href="https://twitter.com/DankiCodeAI"
            >
              <Twitter size={32} />
            </Link>
            <Link
              _hover={{
                svg: { stroke: 'green.400' }
              }}
              href="https://www.linkedin.com/company/dankicodeoficial/mycompany/"
            >
              <Linkedin size={32} />
            </Link>
            <Link
              _hover={{
                svg: { stroke: 'green.400' }
              }}
              href="https://www.youtube.com/@DankiCodeAI"
            >
              <Youtube size={32} />
            </Link>
          </Flex>
        </Flex>
      </Flex>

      <Flex
        w={'100%'}
        gap={'32px'}
        align={'center'}
        maxW={1216}
        justify={'space-between'}
        px={'20px'}
        flexWrap={'wrap'}
      >
        <Flex flexDir={'column'}>
          <Text fontSize={'sm'} color={'grey.300'}>
            Danki Code AI - 51.054.349/0001-47
          </Text>
          <Text fontSize={'sm'} color={'grey.300'}>
            {new Date().getFullYear()} © Todos os direitos reservados
          </Text>
        </Flex>

        <LogoHorizontal w={'130px'} h={'24px'} />
      </Flex>

      <Divider borderColor={'grey.600'} maxW={1216} />

      <Flex
        flexWrap={'wrap'}
        gap={'32px'}
        justify={'space-between'}
        align={'center'}
        maxW={1216}
        w={'100%'}
        px={'20px'}
        // flexDirection={['column', 'row']}
      >
        <Link isExternal href={'https://copygenerator.ai'}>
          <CopyGenerator w={'168px'} h={'100%'} />
        </Link>
        <Link isExternal href={'https://ebookgenerator.ai'}>
          <EbookGenerator w={'178px'} h={'100%'} />
        </Link>
        <Link isExternal href={'https://codequick.ai'}>
          <CodeQuick w={'158px'} h={'100%'} />
        </Link>
        <Link isExternal href={'https://myimages.ai'}>
          <Image
            src="https://d1k1f4n2h095ym.cloudfront.net/myimages-logo.png"
            w={'158px'}
            h={'100%'}
          />
        </Link>
        <Link isExternal href={'https://voiceclone.dankicode.ai'}>
          <VoiceClone w={'148px'} h={'100%'} />
        </Link>
        <Link isExternal href={'https://gpt4everyone.ai/'}>
          <Image
            src="https://d1k1f4n2h095ym.cloudfront.net/gpt32k-logo.png"
            w={'158px'}
            h={'100%'}
          />
        </Link>
        <Link isExternal href={'https://gpt-32k.dankicode.ai'}>
          <Gpt32k w={'108px'} h={'100%'} />
        </Link>
      </Flex>
    </Flex>
  )
}
